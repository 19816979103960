import axios from "../plugins/axios";
import { PORTAL_CONFIG } from "../config";

export default {
  // eslint-disable-next-line no-unused-vars
  getUserProfile: function() {
    return axios.get(`/getMyProfiles/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  getFullSession() {
    return axios.get(`/getFullSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  getUserData(id) {
    return axios.get(
      `/User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getEntity: function() {
    return axios.get(
      `/Entity/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getUserEntity: function() {
    return axios.get(`/getMyEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  setActiveEntity(entity_id) {
    return axios.post(
      `changeActiveEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}&entities_id=${entity_id}`
    );
  },
  occupants() {
    return axios.get(
      `/PluginGenericobjectOccupant/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getOccupants() {
    return axios.get(
      `/PluginFieldsEntitypiloteimmooccupant/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getCharge() {
    return axios.get(
      `/PluginGenericobjectCharge/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getTravaux() {
    return axios.get(
      `/PluginGenericobjectTravauxger/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getDocuments(entity_id) {
    return axios.get(
      `/Entity/${entity_id}/Document_Item/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getDocument(document_id, blob = false) {
    if (blob) {
      return axios(
        `/Document/${document_id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
        {
          responseType: "blob" // important,
        }
      );
    } else {
      return axios.get(
        `/Document/${document_id}?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
      );
    }
  },
  downloadDocument(item) {
    axios(
      `/Document/${item.id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  openDocument(item) {
    axios(
      `/Document/${item.id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = URL.createObjectURL(response.data);
      window.open(
        url,
        item.filename,
        "menubar=no,status=no,scrollbars=no,width=800,height=800"
      );
    });
  },

  getEquipment(equipmentNom) {
    return axios.get(
      `/PluginGenericobject${equipmentNom}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getEquipmentTicketItems(equipmentId, equipmentNom) {
    return axios.get(
      `/PluginGenericobject${equipmentNom}/${equipmentId}/Item_Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getRoom() {
    return axios.get(
      `/PluginGenericobjectRoom/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getRoomTicketItems(roomId) {
    return axios.get(
      `/PluginGenericobjectRoom/${roomId}/Item_Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getAllTickets() {
    return axios.get(
      `/Ticket/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&order=DESC&get_hateoas=false`
    );
  },
  getTickets() {
    return axios.get(
      `/Ticket/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&order=DESC&expand_dropdowns=true&get_hateoas=false`
    );
  },
  getTicketsEnt(ent) {
    return axios.get(
      `/Ticket/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&order=DESC&expand_dropdowns=true&get_hateoas=false&searchText[entities_id]=${ent}`
    );
  },
  getTicket(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getTicketsUsers() {
    return axios.get(
      `/Ticket_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&searchText[type]=2&range=0-10000`
    );
  },
  getITILSolution(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/ITILSolution/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getITILFollowup(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/ITILFollowup/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getTicketTask(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/TicketTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getPhotos() {
    return axios.get(
      `/Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  changeActiveEntity() {
    return axios.post(
      `changeActiveEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  loadDoc() {
    return axios.get(
      `Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&expand_dropdowns=true`
    );
  },
  loadSpecificDoc(cat) {
    return axios.get(
      `Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&expand_dropdowns=true&searchText[documentcategories_id]=${cat}`
    );
  },
  getActiveEntity() {
    return axios.get(`/Entity/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  updateComment(id, comment) {
    return axios.put(`/Entity/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        comment
      }
    });
  },

  getAllITILSolutions() {
    return axios.get(
      `/ITILSolution/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getAllITILFollowups() {
    return axios.get(
      `/ITILFollowup/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  getLocations() {
    return axios.get(
      `/Location/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  addNote(entityID, note) {
    return axios.post(`/Notepad/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        content: note,
        itemtype: "entity",
        items_id: entityID
      }
    });
  },
  getEntityNote(entityID) {
    return axios.get(
      `/Entity/${entityID}/Notepad/?app_token=${PORTAL_CONFIG.APP_TOKEN}&order=DESC&range=0-10000`
    );
  },
  getProjects() {
    return axios.get(
      `/Project/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getProjectTask(projectID) {
    return axios.get(
      `/${projectID}/ProjectTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getAllProjectTask() {
    return axios.get(
      `/ProjectTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getDocumentCategories() {
    return axios.get(
      `/DocumentCategory/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000`
    );
  },
  getUsers() {
    return axios.get(
      `/User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=0-10000&get_hateoas=false`
    );
  },
  addImageDoc(uploadImage, originalImg, name, categorie) {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    formData.append(
      "uploadManifest",
      JSON.stringify({
        input: {
          name: name,
          _filename: [originalImg.name],
          documentcategories_id: categorie,
          comment: "XXX"
        }
      })
    );
    formData.append("filename[0]", uploadImage);
    return axios.post(
      `/Document?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      formData
    );
  },
  addNewDocument(document, name, categorie) {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    formData.append(
      "uploadManifest",
      JSON.stringify({
        input: {
          name: name,
          _filename: [document.name],
          documentcategories_id: categorie,
          comment: "XXX"
        }
      })
    );
    formData.append("filename[0]", document);

    return axios.post(
      `/Document?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      formData
    );
  }
};
