import Vue from "vue";
import VueRouter from "vue-router";

const RouterView = () => import("../views/Router.vue");

Vue.use(VueRouter);

// eslint-disable-next-line no-unused-vars
import before from "../middlewares/before";

import { redirect } from "../middlewares/redirect";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/assets/index",
    component: RouterView,
    children: [
      {
        path: "assets",
        name: "assets",
        component: RouterView,
        children: [
          {
            path: "l2/:assetID",
            name: "assets.l2.show",
            component: () => import("../views/AssetLevel2")
          },
          {
            path: "index/:assetID?",
            name: "assets.index",
            component: () => import("../views/Asset")
          },
          {
            path: ":id",
            name: "assets.show",
            component: () => import("../views/AssetDetails")
          }
        ]
      },
      {
        path: "lots",
        name: "lots",
        component: RouterView,
        children: [
          {
            path: "index/:assetID?",
            name: "lots.index",
            component: () => import("../views/Lot")
          },
          {
            path: ":id",
            name: "lots.show",
            component: () => import("../views/LotDetails")
          },
          {
            path: ":id/ticket/:ticketId",
            name: "lots.ticket.show",
            component: () => import("../views/TicketDetail")
          }
        ]
      },
      {
        path: "/view/attestation",
        name: "view.attestation",
        component: () => import("../views/Attestation"),
        meta: {
          guestGuard: true,
          directLink: true
        }
      },
      {
        path: "/view/lot/:id/u/:userToken",
        name: "view.lot",
        component: () => import("../views/ViewLot"),
        meta: {
          guestGuard: true,
          directLink: true
        }
      },
      {
        path: "/view/resa/:id/u/:userToken",
        name: "view.reservation",
        component: () => import("../views/Reservation"),
        meta: {
          guestGuard: true,
          directLink: true
        }
      },
      {
        path: "/view/ticket/:ticketId/u/:userToken",
        name: "view.ticket",
        component: () => import("../views/TicketDetail"),
        meta: {
          guestGuard: true,
          directLink: true
        }
      },
      {
        path: "/view/support/:id/u/:userToken/it/:itemtype/itid/:itemtypeId",
        name: "view.support",
        component: () => import("../views/ViewSupport"),
        meta: {
          guestGuard: true,
          directLink: true,
          directIt: true
        }
      },
      {
        path: "/view/tuto/:id/u/:userToken",
        name: "view.tuto",
        component: () => import("../views/ViewTuto"),
        meta: {
          guestGuard: true,
          directLink: true,
          directIt: true
        }
      },
      {
        path: "/view/calendar/:id/u/:userToken",
        name: "view.calendar",
        component: () => import("../views/ViewCalendar"),
        meta: {
          guestGuard: true,
          directLink: true,
          directView: true
        }
      },
      {
        path: "/ecran/lot/:id/u/:userToken",
        name: "ecran",
        redirect: "/ecran/lot/:id/u/:userToken/entreprise",
        component: RouterView,
        meta: {
          guestGuard: true,
          directLink: true,
          fromEcran: true
        },
        children: [
          {
            path: "incident",
            name: "ecran.incident",
            component: () => import("../views/ViewLot"),
            meta: {
              guestGuard: true,
              directLink: true,
              fromEcran: true
            }
          },
          {
            path: "performance",
            name: "ecran.performance",
            component: () => import("../views/ecran/Performance"),
            meta: {
              guestGuard: true,
              directLink: true,
              fromEcran: true
            }
          },
          {
            path: "entreprise",
            name: "ecran.entreprise",
            component: () => import("../views/ecran/Entreprise"),
            meta: {
              guestGuard: true,
              directLink: true,
              fromEcran: true
            }
          },
          {
            path: "calendar",
            name: "ecran.calendar",
            component: () => import("../views/ecran/Calendar"),
            meta: {
              guestGuard: true,
              directLink: true,
              fromEcran: true
            }
          },
          {
            path: "event/:page",
            name: "ecran.event",
            component: () => import("../layouts/Event"),
            meta: {
              guestGuard: true,
              directLink: true,
              fromEvent: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: redirect,
    meta: {
      guestGuard: true,
      title: "Login"
    },
    component: () => import("../views/Auth/Login")
  },
  {
    path: "/login/u/:user_token",
    name: "direct_login",
    beforeEnter: redirect,
    meta: {
      guestGuard: true,
      title: "Login"
    },
    component: () => import("../views/Auth/Login")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(before);

export default router;
