<template>
  <v-col cols="12">
    <v-card class="mt-6" flat min-width="300px">
      <v-card v-if="isCartoMap" flat width="98%" class="mb-6">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card-title class="headline display-1">
        {{ $t("tableTitle.accommodation_list") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('tableStatus.searchAll')"
          single-line
          hide-details
          class="mt-n2"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @click:row="clicked"
        >
          <template v-slot:item="{ item }">
            <c-row
              :item="item"
              @click="clicked(item)"
              :headers="headers"
              rtl
              ignore-null-val
            ></c-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import MapView from "../../components/Map";
//import moment from "../../plugins/moment";
import USER_API from "../../api/user";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "PluginGenericobjectLogement",
  components: { MapView },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      search: "",
      isCartoMap: true,
      locationList: [],
      loading: false,
      headers: [
        {
          text: "Nom",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Etat",
          align: "left",
          sortable: false,
          value: "operationnel"
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: "Incident en cours",
          align: "center",
          sortable: false,
          value: "tickets",
          class: "primary white--text"
        },
        {
          text: "Incidents résolus",
          align: "center",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text"
        },
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "dimension"
        } /*{,
        
          text: "Année d'installation",
          align: "right",
          sortable: false,
          value: "creationdate"
        },
        {
          text: "Durée de vie résiduelle",
          align: "right",
          sortable: false,
          value: "duree"
        }*/
      ]
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getAllTickets"
    ]),
    items() {
      return this.list.map(entity => {
        const ticketdata = this.getAllTicketsData(entity);
        entity.tickets = ticketdata[0] > 0 ? ticketdata[0] : 0;
        entity.technique = ticketdata[1] > 0 ? ticketdata[1] : 0;
        return entity;
      });
    }
  },
  methods: {
    clicked: function(item) {
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/itemtype.php?user=" +
          this.getUserToken +
          "&ept=" +
          item.code_pi +
          "&entity=" +
          this.$route.params.id
      );
    },
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getEquipment("Logement")
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            this.locationList = [];
            let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              for (let i = 0; i < size; i++) {
                if (this.list[i].gps && this.list[i].gps !== "/") {
                  nbgps = nbgps + 1;
                  this.locationList.push({
                    name:
                      isNaN(this.list[i].name) === true
                        ? this.list[i].name.toUpperCase()
                        : this.list[i].name,
                    operationnel: this.list[i].operationnel,
                    /* si souci avec les markers, il faudrait peut etre utiliser cela
                    operationnel:
                      this.list[i].operationnel !== undefined
                        ? this.list[i].operationnel
                        : "NA",*/
                    lat: this.list[i].gps.split("/")[0],
                    lng: this.list[i].gps.split("/")[1],
                    link:
                      PORTAL_CONFIG.SERVER_WEB +
                      "/" +
                      PORTAL_CONFIG.APP_NAME +
                      "/" +
                      PORTAL_CONFIG.CLIENT_NAME +
                      "/itemtype.php?user=" +
                      this.getUserToken +
                      "&ept=" +
                      this.list[i].code_pi +
                      "&entity=" +
                      this.$route.params.id
                  });
                }
              }
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                if (this.list[i].operationnel === 1) {
                  this.list[i].operationnel = "OK";
                } else {
                  this.list[i].operationnel = "NOK";
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
                this.list[i].dimension = this.list[i].dimension;
                /*if (this.list[i].expirationdate != null) {
                  this.list[i].duree =
                    this.list[i].expirationdate.split("-")[0] -
                    moment().format("YYYY") +
                    " an(s)";
                }*/
              }
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getAllTicketsData(entity) {
      const ticketsAll = this.getAllTickets.filter(ticket => {
        try {
          const name = JSON.parse(ticket.name);
          return name.itemtypeID == entity.id && ticket.itilcategories_id === 1;
        } catch {
          return false;
        }
      });
      const status1 = ticketsAll.filter(
        st1 => st1.status === 5 || st1.status === 6
      );
      const status2 = ticketsAll.filter(
        st2 => st2.status !== 5 && st2.status !== 6
      );
      return [
        status2.length > 0 ? status2.length : "-",
        status1.length > 0 ? status1.length : "-"
      ];
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
