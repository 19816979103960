<template>
  <v-app id="ecran">
    <v-navigation-drawer
      class="primary"
      dark
      app
      persistent
      :width="nav_bar_width"
      heigth="100vh"
      id="navBar"
    >
      <v-list v-if="moduleData">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-row style="width: 200px; height: 320px;">
              <v-col
                cols="12"
                class="d-flex justify-center align-center fill-height pa-0"
                style="width: 100%;"
              >
                <iframe
                  :src="src_iframe"
                  width="100%"
                  height="100%"
                  id="menu-frame"
                  sandbox="allow-scripts allow-same-origin allow-top-navigation"
                  allowTransparency="true"
                  scrolling="no"
                  frameborder="0"
                  v-if="params && params.id"
                ></iframe>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ecran.entreprise', params }"
          v-if="displayedModule('module1')"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="d-flex justify-center align-center title"
              >{{ moduleData.module1 }}</v-list-item-title
            >
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <qrcode-vue
                  :size="qr_code_width"
                  :value="links.entrepriseURL"
                  level="L"
                ></qrcode-vue>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ecran.incident', params }"
          v-if="displayedModule('module2')"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="d-flex justify-center align-center title"
              >{{ moduleData.module2 }}</v-list-item-title
            >
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <qrcode-vue
                  :size="qr_code_width"
                  :value="links.incidentUrl"
                  level="L"
                ></qrcode-vue>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ecran.calendar', params }"
          v-if="displayedModule('module3')"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="d-flex justify-center align-center title"
              >{{ moduleData.module3 }}</v-list-item-title
            >
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <qrcode-vue
                  :size="qr_code_width"
                  :value="links.calendarURL"
                  level="L"
                ></qrcode-vue>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ecran.performance', params }"
          v-if="displayedModule('module4')"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="d-flex justify-center align-center title"
              >{{ moduleData.module4 }}</v-list-item-title
            >
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <qrcode-vue
                  :value="links.performanceURL"
                  level="L"
                  :size="qr_code_width"
                ></qrcode-vue>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-skeleton-loader
        ref="skeleton"
        type="list-item-three-line"
        class="mx-auto"
        v-else
      ></v-skeleton-loader>
    </v-navigation-drawer>

    <v-content>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-content>

    <app-footer></app-footer>
  </v-app>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { PORTAL_CONFIG } from "../config";
import { LayoutOptions } from "../mixins/layouts";
import Footer from "../components/ecran/Footer";
import { channel } from "../plugins/pusher";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../event-bus";
import {
  NAVBAR_WIDTH,
  QRCODE_WIDTH,
  REDIRECT_TIMEOUT,
  loadStates
} from "../utils";
import { TicketsData } from "../mixins/tickets-data";
import USER_API from "../api/user";
import TICKET_API from "../api/ticket";

export default {
  name: "Ecran",
  components: {
    QrcodeVue,
    "app-footer": Footer
  },
  data() {
    return {
      nav_bar_width: NAVBAR_WIDTH,
      qr_code_width: QRCODE_WIDTH,
      moduleData: null,
      moduleLoaded: false
    };
  },
  computed: {
    ...mapGetters([
      "getEquipmentLoadStatus",
      "getEquipments",
      "getTicketsTasksLoadStatus",
      "getUUID",
      "sessionIsInit",
      "getTicketsTasksLoadStatus",
      "getAllITILSolutions",
      "getAllITILSolutionsLoadStatus",
      "getAllITILFollowups",
      "getAllITILFollowupsLoadStatus"
    ]),
    src_iframe() {
      return (
        PORTAL_CONFIG.SERVER_WEB +
        "/" +
        PORTAL_CONFIG.APP_NAME +
        "/" +
        PORTAL_CONFIG.CLIENT_DATA +
        "/" +
        this.params.id +
        "/menu.html"
      );
    },
    params() {
      return {
        id: this.$route.params.id,
        userToken: this.$route.params.userToken
      };
    },
    links() {
      return {
        incidentUrl: `${PORTAL_CONFIG.SERVERLESS_URL}/page?page=incident&uuid=${this.uniqID}`,
        performanceURL: `${PORTAL_CONFIG.SERVERLESS_URL}/page?page=performance&uuid=${this.uniqID}`,
        calendarURL: `${PORTAL_CONFIG.SERVERLESS_URL}/page?page=calendar&uuid=${this.uniqID}`,
        entrepriseURL: `${PORTAL_CONFIG.SERVERLESS_URL}/page?page=entreprise&uuid=${this.uniqID}`
      };
    },
    sessionUUID() {
      return this.moduleData && this.moduleData.qr_dyn == 1
        ? this.getUUID
        : "pilot-immot-app";
    },
    uniqID() {
      return this.$route.params.id
        ? `${this.sessionUUID}-${this.$route.params.id}`
        : null;
    }
  },
  mixins: [LayoutOptions, TicketsData],
  created() {
    this.logUser();
    this.loadModulesData();
  },
  mounted() {
    if (this.uniqID && this.moduleLoaded) {
      this.initEvents();
    }

    const menuFrame = document.getElementById("menu-frame");
    const interval = 60000 * 60; //ce sont de millièmes de sec
    setInterval(() => {
      menuFrame &&
        menuFrame.contentWindow &&
        menuFrame.contentWindow.location.reload();
    }, interval);
    setInterval(() => {
      const url =
        PORTAL_CONFIG.SERVER_WEB +
        "/interne/api_ecran.php?entity=" +
        this.$route.params.id;
      TICKET_API.pingEcran(url);
      //TICKET_API.addTicketClos(this.$route.params.id);
    }, interval);
  },
  methods: {
    ...mapActions([
      "loadTicketsTasks",
      "loadAllITILSolutions",
      "loadAllITILFollowups",
      "loadAllTickets",
      "loadItemType"
    ]),
    navigate(link) {
      this.$router.push({ name: link, params: this.params });
      if (link !== "ecran.entreprise") {
        setTimeout(() => {
          this.$router.push({ name: "ecran.entreprise", params: this.params });
        }, REDIRECT_TIMEOUT);
      }
    },
    async loadData() {
      if (this.isAuth) {
        USER_API.setActiveEntity(this.$route.params.id).then(() => {
          if (this.getAllTicketsLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getAllTickets().then(async response => {
              const tickets = await this.loadEquipmentsData(response.data);
              TICKET_API.getTasks().then(({ data }) => {
                let tasks = [];
                tickets.forEach(ticket => {
                  const ticketTasks = data
                    .filter(task => task.tickets_id === ticket.id)
                    .map(task => {
                      task.entities_id = ticket.entities_id;
                      return task;
                    });
                  if (ticketTasks && ticketTasks.length > 0) {
                    tasks = tasks.concat(ticketTasks);
                  }
                });
                this.$store.commit("setTicketsTasks", tasks);
                this.$store.commit(
                  "setTicketsTasksLoadStatus",
                  loadStates.SUCCESS
                );
              });
            });
          }

          if (this.getEquipmentLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getLocations().then(({ data }) => {
              this.loadEquipment(data);
            });
          }

          if (this.getAllITILSolutionsLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getAllITILSolutions().then(response => {
              this.loadAllITILSolutions(response.data);
            });
          }
          if (this.getAllITILFollowupsLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getAllITILFollowups().then(response => {
              this.loadAllITILFollowups(response.data);
            });
          }
        });
      }
    },
    logUser() {
      if (!this.isAuth || (this.isAuth && !this.sessionIsInit)) {
        if (this.params.userToken) {
          this.$store
            .dispatch("initSession", this.params.userToken)
            .then(() => {
              this.loadData();
            });
        }
      }

      if (this.sessionIsInit) {
        this.loadData();
      }
    },
    displayedModule(key) {
      if (!this.moduleData) return true;
      if (this.moduleData[key] == 0) return false;
      return this.moduleData[key];
    },
    loadModulesData() {
      let id = this.$route.params.id;
      if (id) {
        this.$store.commit("setModulesLoadState", loadStates.PENDING);
        fetch(
          `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_DATA}/${id}/data.php`
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.moduleData = data.param;
            this.$store.commit("setNewModule", data.param);
            this.$store.commit("setModulesLoadState", loadStates.SUCCESS);
            this.moduleLoaded = true;
          });
      }
    },
    async loadEquipmentsData(ticketsData) {
      const tickets = ticketsData.map(ticket => {
        ticket.equipment =
          ticket.locations_id === 0 ? "Sans Equipement" : ticket.locations_id;
        ticket.no_equipment = ticket.locations_id === 0;
        return ticket;
      });
      this.loadAllTickets(tickets);
      return tickets;
    },
    initEvents() {
      channel
        .bind(this.uniqID, data => {
          const link = `ecran.${data.page}`;
          this.navigate(link);
        })
        .bind(`calendar-${this.uniqID}`, data => {
          EventBus.$emit("calendar", data);
        });
      if (this.params.id) {
        channel.bind(`entreprise-${this.params.id}`, data => {
          EventBus.$emit("entreprise", data);
        });
      }
    }
  },
  watch: {
    getEquipmentLoadStatus() {
      if (this.getEquipmentLoadStatus === loadStates.SUCCESS) {
        this.loadTicketsData();
      }
    },
    params() {
      this.loadModulesData();
      this.logUser();
      if (this.params.id && this.moduleLoaded) {
        this.initEvents();
      }
    },
    moduleLoaded() {
      if (this.params.id && this.moduleLoaded) {
        this.initEvents();
      }
    }
  },
  destroyed() {
    // Destroy event listening
    EventBus.$off("entreprise");
  }
};
</script>

<style scoped></style>
