import { mapActions, mapGetters } from "vuex";
import { sumBy, reject, flatten } from "../utils";
import moment from "../plugins/moment";
import { Decoder } from "../mixins/decodeContent";

export const AssetsData = {
  data() {
    return {
      list: []
    };
  },
  mixins: [Decoder],
  computed: {
    ...mapGetters([
      "getEntityLoadStatus",
      "getUserProfileStatus",
      "getOccupants",
      "getCharges",
      "getAllTickets",
      "getUserProfiles",
      "getTravaux",
      "getDocuments",
      "getDocumentsImages",
      "getTicketsTasks",
      "getReservations",
      "getProjetTasks",
      "getAssets"
    ]),
    getLevel3Entity() {
      return this.getTreeData &&
        this.getTreeData[0] &&
        this.getTreeData[0].level === 2
        ? flatten(this.getTreeData.map(el => el.children))
        : this.getTreeData;
    }
  },
  methods: {
    ...mapActions([
      "loadEntity",
      "loadUserProfile",
      "loadEntities",
      "addAsset",
      "updateAsset"
    ]),
    getEntityData(entity, children = null) {
      let child = children;
      if (children && children.length > 0) {
        const soldefield = this.getDataForAssetMinus(child, "soldefield");
        entity.soldefield = soldefield != 0 ? soldefield : "-";
      }
      if (children && children.length > 0) {
        const loyerannuelhtfield = this.getDataForAsset(
          child,
          "loyerannuelhtfield"
        );
        entity.loyerannuelhtfield =
          loyerannuelhtfield > 0 ? loyerannuelhtfield : "-";
      }

      const financiers = this.getChargesData(entity, child);
      entity.charge = financiers[0];
      entity.impot = financiers[1];

      const charge = this.getValue(entity, "charge");
      const impot = this.getValue(entity, "impot");
      entity.charge = charge > 0 ? charge : "-";
      entity.impot = impot > 0 ? impot : "-";

      const investissements = this.getTravauxData(entity, child);
      entity.travaux1 = investissements[0];
      entity.investissement = investissements[1];
      entity.travaux2 = investissements[2];

      const travaux1 = this.getValue(entity, "travaux1");
      const investissement = this.getValue(entity, "investissement");
      const travaux2 = this.getValue(entity, "travaux2");
      entity.travaux1 = travaux1 > 0 ? travaux1 : "-";
      entity.investissement = investissement > 0 ? investissement : "-";
      entity.travaux2 = travaux2 > 0 ? travaux2 : "-";

      const ticketdata = this.getAllTicketsData(entity, child);
      entity.tickets = ticketdata[0] > 0 ? ticketdata[0] : 0;
      entity.technique = ticketdata[1] > 0 ? ticketdata[1] : 0;

      entity.events = this.loadCalendarData(entity, child);

      return entity;
    },
    listData() {
      this.getLevel3Entity &&
        this.getLevel3Entity.forEach(entity => {
          this.loadEntityData(entity);
        });
      this.$store.commit("SET_KEY");
    },
    loadEntityData(entity) {
      const childrenData = [];
      if (entity.children) {
        const children = this.getChildElements(entity).sort((a, b) => {
          if (b.level > a.level) return 1;
          if (b.level < a.level) return -1;
          return 0;
        });
        //console.log(children);
        children.forEach(child => {
          child.parent = child.level === entity.level + 1 ? entity.id : null;
          const grandChildren =
            child.level < 5 ? this.getChildElements(child) : [];
          const newChild = this.getEntityData(child, grandChildren);
          childrenData.push(newChild);
          //console.log(child.level);
          /*if ([3, 4].includes(child.level) && child.children) {
            this.loadEntityData(newChild, true);
          }*/
        });
      }
      const newEntity = this.getEntityData(entity, childrenData);
      this.addElement(newEntity);

      childrenData.forEach(child => {
        this.addElement(child);
      });
    },
    addElement(entity) {
      const index = this.list.findIndex(el => el.id === entity.id);
      if (index > -1) {
        this.$set(this.list, index, entity);
        this.updateAsset({ entity, index });
      } else {
        this.list.push(entity);
        this.addAsset(entity);
      }
    },
    getChargesData(entity, children = null) {
      let sum1 = 0;
      let sum2 = 0;
      //console.log(entity, children);
      if (entity.level === 4) {
        const filteredChild = children.filter(
          child => child.partiescommunefield === 0
        );
        sum1 = sumBy(filteredChild, "charge");
        sum2 = sumBy(filteredChild, "impot");
      } else {
        const charges = this.getCharges.filter(
          el => el.entities_id === entity.id
        );
        const impot1 = charges.filter(el1 => el1.impot === 0);
        const impot2 = charges.filter(el2 => el2.impot === 1);

        impot1.forEach(function(charge) {
          sum1 += +charge.budget;
        });
        impot2.forEach(function(charge) {
          sum2 += +charge.budget;
        });
      }

      return [sum1 > 0 ? sum1 : "-", sum2 > 0 ? sum2 : "-"];
    },
    getAllTicketsData(entity, children = null) {
      if (entity.level === 4) {
        const tickets = sumBy(children, "tickets");
        const technique = sumBy(children, "technique");
        return [tickets, technique];
      } else {
        const ticketsAll = this.getAllTickets.filter(
          el => el.entities_id === entity.id && el.itilcategories_id === 1
        );
        const status1 = ticketsAll.filter(
          st1 => st1.status === 5 || st1.status === 6
        );
        const status2 = ticketsAll.filter(
          st2 => st2.status !== 5 && st2.status !== 6
        );
        return [
          status2.length > 0 ? status2.length : "-",
          status1.length > 0 ? status1.length : "-"
        ];
      }
    },
    getTravauxData(entity, children = null) {
      let travaux1 = 0,
        investissement = 0,
        travaux2 = 0;

      if (entity.level === 4) {
        const filteredChild = children.filter(
          child => child.partiescommunefield === 0
        );
        travaux1 = sumBy(filteredChild, tr => tr.travaux1);
        investissement = sumBy(filteredChild, tr => tr.investissement);
        travaux2 = sumBy(filteredChild, tr => tr.travaux2);
      } else {
        const entityTravaux = this.getTravaux.filter(
          trav => trav.entities_id === entity.id
        );
        travaux1 = sumBy(entityTravaux, tr => tr.realise);
        investissement = sumBy(entityTravaux, tr => tr.budget);
        travaux2 = sumBy(entityTravaux, tr => tr.afaire);
      }

      return [travaux1, investissement, travaux2];
    },
    getValue(entity, key) {
      if (
        entity.level === 4 &&
        entity.parent &&
        entity.partiescommunefield !== 1
      ) {
        let parent = this.getTreeData.find(el => el.id === entity.parent);
        if (!parent) {
          const entities = flatten(this.getTreeData.map(el => el.children));
          parent = entities.find(el => el.id === entity.parent);
        }
        let children = this.getChildElements(parent, false);
        const commun = children.find(el => {
          return el.partiescommunefield === 1 && !!el[key] && el[key] != "-";
        });
        const filteredChild = entity.children.filter(
          child => child.partiescommunefield === 0
        );
        const value = commun
          ? (entity.surfacemtwofield * commun[key]) / parent.surfacemtwofield
          : sumBy(filteredChild, key);

        //console.log(parent.label, parent, value)
        return value ? Math.round(value) : 0;
      }
      return entity[key];
    },
    getDataForAsset(children, key) {
      const data = reject(el => !el.label, children);
      const sum = data ? sumBy(data, el => (el[key] > 0 ? el[key] : 0)) : 0;
      return sum ? sum : 0;
    },
    getDataForAssetMinus(children, key) {
      const data = reject(el => !el.label, children);
      const sum = data ? sumBy(data, el => (el[key] != 0 ? el[key] : 0)) : 0;
      return sum ? sum : 0;
    },
    getChildrenData(entity, key) {
      const filteredChild = entity.children.filter(
        child => child.partiescommunefield === 0
      );
      return sumBy(filteredChild, key);
    },
    loadCalendarData(entity, children) {
      if (entity.level === 4) {
        return `${children.map(child => `${child.events}`).join("<br/>")}`;
      }
      const tickets = this.getTicketsTasks.filter(
        ticket => ticket.entities_id === entity.id
      );
      const reservations = this.getReservations.filter(
        reservation => reservation.entities_id === entity.id
      );

      const projectTasks = this.getProjetTasks
        .filter(project => project.entities_id === entity.id)
        .map(project => {
          project.begin = project.plan_start_date;
          project.end = project.plan_end_date;
          project.comment = project.content;
          return project;
        });

      const events = tickets.concat(reservations).concat(projectTasks);

      const filteredEvents = events.filter(event => {
        const date = moment().add(30, "days");
        if (!event.end) return false;
        const end = moment(event.end, "YYYY-MM-DD HH:mm:ss");
        const today = moment();
        return end.isAfter(today) && end.isBefore(date);
      });

      return `<div>${filteredEvents
        .map(event => this.getEvent(event))
        .join("<br/>")}</div>`;
    },
    formatDate(a, withTime = true) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getEvent(event) {
      //console.log(event)
      const date = moment(event.begin, "YYYY-MM-DD HH:mm:ss");
      const begin = date.format("HH");
      const end = event.end
        ? moment(event.end, "YYYY-MM-DD HH:mm:ss").format("HH")
        : "";
      const content = event.comment
        ? this.decodeContent(event.comment)
        : this.decodeContent(event.content);
      const len = content.length;
      const deb = content.slice(0, 3);
      const fin = content.slice(len - 4, len);
      let titre = content;
      if (deb === "<p>" && fin === "</p>") {
        titre = content.slice(3, len - 4);
      }
      return `<span>${date.format("DD/MM")} ${begin}-${end}h : ${titre}</span>`;
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    getChildElements(parent, onlyChildren = true) {
      if (parent.level === 5) return [];
      let result = parent.children
        ? onlyChildren
          ? flatten(
              parent.children.map(el =>
                el.level < 5 ? this.getChildElements(el, false) : el
              ),
              3
            )
          : flatten(
              [
                [parent],
                parent.children.map(el =>
                  el.level < 5 ? this.getChildElements(el, false) : el
                )
              ],
              3
            )
        : [];
      return result;
    }
  },
  watch: {
    getTreeData: {
      deep: true,
      handler: function() {
        if (this.loaded) {
          this.listData();
        }
      }
    },
    getTicketsTasks: {
      deep: true,
      handler: function() {
        if (this.loaded) {
          this.listData();
        }
      }
    }
  }
};
