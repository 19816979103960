import fr from "vuetify/es5/locale/fr";

export default {
  $vuetify: { ...fr },

  dashboard: {
    assets_list: " Liste des assets",
    logout: "Se déconnecter",
    default_page_title: "Pilote IMMO, ici vos actifs ont de la valeur",
    help_text:
      "En cas de difficulté, se déconnecter puis accéder de nouveau à l'application. Utiliser le navigateur Chrome de préférence."
  },

  login: {
    login_to_account: "Veuillez vous connecter pour accéder à votre compte",
    connect: "Se Connecter",
    user_token: "Token Utilisateur",
    basic_auth: "Basic Auth"
  },
  asset: {
    list_assets: "LISTE DES ASSETS"
  },
  header: {
    theme: "THÈME",
    info: "INFO",
    generics: "GÉNÉRIQUES",
    commitment: "ENGAGEMENT",
    lease: "DU BAIL",
    financial: "FINANCIER",
    works: "TRAVAUX",
    perimeter: "Périmètre",
    opening_date: "Date d'ouverture",
    date_grant: "Date d'attribution",
    resolution_date: "Resolution date",
    status: "Statut",
    description: "Description",
    category: "Catégorie",
    email: "Contact",
    last_comment: "Dernier commentaire",
    user: "Utilisateur",
    access: "accès",
    levy: "Charges, impôts et taxes",
    name: "Nom",
    note: "Remarque",
    creation_date: "Date création",
    nature: "Nature",
    budget: "Budget N",
    engaged: "Engagé N",
    executed: "Réalisé N-1",
    commercial_name: "Nom Commercial",
    surface: "Surface",
    location: "Emplacement",
    signatory: "Signataire du Bail",
    lease_start: "Date Début Bail",
    lease_end: "Date Fin Bail",
    termination_date: "Date Limite de Résilitation",
    annual_rent: "Loyer Annuel € HT Hors Charges",
    current_balance: "Solde",
    building: "Bâtiment",
    stage: "Etage",
    shape: "Etat",
    measurement: "Dimension",
    year_installation: "Année d'installation",
    residual_life: "Durée de vie résiduelle",
    comment: "commentaire",
    list_performance: " Liste des dernières prestations",
    list_reported: "Liste des dernières interventions remontées",
    rental: "État locatif",
    work: "Programme de travaux",
    tenant_account: "Extraits de compte locataire",
    providers_note: "Note pour les prestataires",
    entity: "Entité",
    society: "Société",
    date: "Date",
    budget_n_1: "A Budgeter N+1",
    realize_last_years: "Réalisé < 5ans",
    image: "Photo"
  },

  subHeader: {
    area: "Surface (m2)",
    tenant: "Locataire",
    location: "Localisation",
    entry_date: "Date d'entrée",
    release_date: "Date de sortie",
    balance: "Solde",
    rents: "Loyers",
    loads: "Charges",
    property_taxes: "Taxe Foncière",
    property_taxes_s: "TF*",
    year_minus_1: "Année N-1",
    year: "Année N",
    year_plus_1: "Année N+1",
    tx_year_minus_1: "Travaux N-1",
    tx_year: "Travaux N",
    tx_year_plus_1: "Travaux N+1",
    processing_tickets: "Nb de tickets en cours",
    resolve_tickets: "Nb de tickets résolus",
    event: "Evènements j+30",
    processing_incidents: "Incident en cours",
    resolved_incidents: "Incidents résolus"
  },

  tableStatus: {
    new: "Nouveau",
    attributed: "Attribué",
    planned: "Planifié",
    in_progress: "En cours",
    resolved: "Résolu",
    closed: "Clos",
    filter: "Filtre",
    by_name: "Par nom",
    per_status: "Par statut",
    by_category: "Et/Ou par catégorie",
    searchAll: "Recherche tous champs",
    searchName: "Recherche dans le nom",
    filter_status: "Filtrer par statut",
    filter_name: "Filtrer par nom",
    access_intructions: "Consignes d'accès",
    filter_perimeter: "Filtrer par périmètre"
  },

  tableTitle: {
    list_lots: "Liste des lots",
    list_access: "Liste des derniers accès",
    list_equipment: "Liste des équipements",
    accommodation_list: "Liste des logements"
  },

  assetDetails: {
    text1:
      "* S'il y a des communs, pour ceux-ci les valeurs correspondent aux charges, TF et montant des travaux de l'ensemble du bâtiment ; pour les lots le montant correspond à la quote-part. ",
    text2: "Ouvrir le document dans une nouvelle fenêtre",
    text3: "Télécharger le document",
    text4: "Commentaire  privé",
    text5: "Remplir une demandes ENEDIS",
    text6: "Attestation ENEDIS"
  },

  index: {
    lease: "Bail",
    account: "Comptabilité",
    plan: "Plan",
    rent: "Loyers",
    management: "Gestion et charges",
    procurement: "Acquisition",
    dpe: "DPE",
    logo: "Logo",
    photo_corporate: "Photo Corporate",
    today: "Aujourd'hui",
    yesterday: "Hier",
    last_days: "30 derniers jours",
    documents: "Documents",
    program: "Programme"
  },

  location: {
    text1: "Liste des emplacements"
  },

  close: "Fermer",
  no_equipment: "Sans Equipement",
  langs: {
    french: "Francais",
    english: "English"
  },
  labels: {
    per_name: "Par Nom",
    start_date: "Date Debut",
    end_date: "Date Fin",
    to: "A",
    no_data: "Pas de données",
    apply: "Appliquer",
    cancel: "Annuler",
    no_category: "Sans Catégorie",
    access_review: "Bilan des accès"
  },
  editor: {
    send: "Envoyer",
    remake: "Refaire",
    cancel: "Annuler",
    horizontal_line: "Ligne horizontale",
    bulk: "Mettre en bloc",
    underline: "Souligné",
    title: "Titre",
    sub_title: "Sous-Titre"
  },
  prestation: {
    monthly_benefit_1: " Prestations mensuelles",
    monthly_benefit_2: "Prestations réalisées par mois"
  },
  calendar: {
    day: "Jour",
    week: "Semaine",
    month: "Mois",
    today: "AUJOURD'HUI"
  }
};
