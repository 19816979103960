/*
	Defines the API route we are using.
*/
var api_url = "",
  front_url = "",
  web_url = "",
  node_url = "",
  serverless_url = "",
  app_token,
  server_web = "",
  app_name = "",
  client_name = "",
  client_data = "";

/*
  Depending on the environment, define the API URL.
*/
switch (process.env.NODE_ENV) {
  case "development":
    api_url = "https://pitrack.pilote.immo/pitrack/apirest.php";
    front_url = "https://pitrack.pilote.immo/pitrack/front";
    app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
    server_web = "https://app.pilote.immo";
    web_url = "http://localhost:8080";
    node_url = "http://192.168.12.100:3000";
    serverless_url = "https://clients.pilote.immo/api";
    app_name = "clients";
    client_name = "main";
    client_data = "ecran";

    break;
  case "production":
    api_url = "https://pitrack.pilote.immo/pitrack/apirest.php";
    front_url = "https://pitrack.pilote.immo/pitrack/front";
    app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
    server_web = "https://app.pilote.immo";
    node_url = "https://clients.pilote.immo";
    serverless_url = "https://clients.pilote.immo/api";
    web_url = "https://clients.pilote.immo";
    app_name = "clients";
    client_name = "main";
    client_data = "ecran";
    break;
}

/*
  Export the cent URL configuration.
*/
export const PORTAL_CONFIG = {
  API_URL: api_url,
  FRONT_URL: front_url,
  APP_TOKEN: app_token,
  SERVER_WEB: server_web,
  WEB_URL: web_url,
  NODE_SERVER: node_url,
  SERVERLESS_URL: serverless_url,
  APP_NAME: app_name,
  CLIENT_NAME: client_name,
  CLIENT_DATA: client_data
};
