<template>
  <component :is="current">
    <slot />
  </component>
</template>

<script>
import Default from "./layouts/Default";
import Login from "./layouts/Login";
import Ecran from "./layouts/Ecran";
import Event from "./layouts/Event";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",

  components: {
    Default,
    Login,
    Ecran,
    Event
  },
  computed: {
    ...mapGetters(["current", "isAuth", "getSessionToken"])
  },
  created() {
    this.setupLang();
    if (
      this.$route.params.user_token ||
      (this.$route.meta && this.$route.meta.directLink)
    ) {
      this.logout();
    }
  },
  methods: {
    ...mapActions(["logout"]),
    setupLang() {
      const local = this.$store.getters.currentLanguage;
      this.$root.$i18n.locale = local;
    }
  }
};
</script>
