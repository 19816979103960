<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      class="blue-grey darken-4"
      dark
      app
      :height="this.$vuetify.breakpoint.height * 0.95"
      hide-overlay
      persistent
      mobile-break-point="1280"
      v-if="!isGuestUser"
    >
      <v-btn
        title
        width="90%"
        light
        class="pa-5 mt-3 ml-2 font-weight-black"
        link
        :to="{ name: 'assets.index' }"
      >
        <v-icon color="primary" class="mr-3">
          mdi-home
        </v-icon>
        {{ $t("dashboard.assets_list") }}</v-btn
      >
      <v-treeview
        :items="treeElements"
        activatable
        hoverable
        rounded
        dense
        open-on-click
        v-if="getTreeData.length"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon color="primary" class="ml-n1">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
        </template>

        <template v-slot:label="{ item }">
          <v-btn
            text
            color="primary"
            link
            title="Lot"
            class="ml-n3"
            v-if="item.level === 5"
            :to="{ name: 'lots.show', params: { id: item.id } }"
          >
            {{ item.name }}</v-btn
          >
          <v-btn
            text
            link
            title="Asset"
            class="ml-n3"
            v-if="item.level === 4"
            :to="{ name: 'assets.show', params: { id: item.id } }"
          >
            {{ item.name }}
          </v-btn>
          <v-btn
            text
            link
            title="Asset"
            class="ml-n3"
            v-if="item.level === 3"
            :to="{ name: 'assets.index', params: { assetID: item.id } }"
          >
            {{ item.name }}
          </v-btn>
          <v-btn
            text
            link
            title="Asset"
            class="ml-n3"
            v-if="item.level === 2"
            :to="{ name: 'assets.l2.show', params: { assetID: item.id } }"
          >
            {{ item.name }}
          </v-btn>
        </template>
      </v-treeview>

      <v-skeleton-loader
        ref="skeleton"
        type="list-item-three-line"
        class="mx-auto"
        v-else
      ></v-skeleton-loader>
    </v-navigation-drawer>
    <v-snackbar v-model="show" :timeout="10000" dark top>
      <v-icon dark>help</v-icon>
      {{ $t("dashboard.help_text") }}
      <v-btn dark text @click="show = false">
        {{ $t("close") }}
      </v-btn>
    </v-snackbar>
    <v-app-bar
      v-if="isDirectLink && isMobilePaysage"
      app
      collapse
      height="30px"
      color="primary"
      dark
    >
      SUPPORT
    </v-app-bar>
    <v-app-bar v-else-if="isDirectLink" app height="30px" color="primary" dark>
      SUPPORT
    </v-app-bar>
    <v-app-bar
      v-else-if="isMobilePaysage"
      app
      collapse-on-scroll
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y :nudge-width="150">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar class="white" size="32">
              <v-icon color="primary">person</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list class="pa-0" light>
          <v-list-item avatar>
            <v-list-item-avatar>
              <v-avatar class="primary" size="48px">
                <v-icon dark>person</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                getUserData && getUserData.registration_number
                  ? getUserData && getUserData.registration_number.toUpperCase()
                  : getUserData && getUserData.name
              }}</v-list-item-title>
              <v-list-item-title>{{
                getUserProfile && getUserProfile.name.toLowerCase().substring(3)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--<v-list-item key="profile" @click="doSomething()">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mon Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
          <v-divider></v-divider>

          <v-list-item key="lock_open" @click="onLogout()">
            <v-list-item-icon>
              <v-icon>lock_open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("dashboard.logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu v-if="isMultiLang" v-model="value" close-on-click>
        <template v-slot:activator="{ on }">
          <v-btn class="ml-3" small color="primary" v-on="on">
            <gb-flag :code="selectedLang.icon" size="micro" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="language" color="primary">
            <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="changeLang"
            >
              <v-list-item-icon>
                <gb-flag :code="item.icon" size="micro" />
              </v-list-item-icon>
              <v-list-item-title>{{
                $t(`langs.${item.key}`)
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn icon @click="show = true">
        <v-icon>help</v-icon>
      </v-btn>
    </v-app-bar>

    <v-app-bar v-else app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{
        pageTitle ? pageTitle : "PILOTE IMMO"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y :nudge-width="150">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar class="white" size="32">
              <v-icon color="primary">person</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list class="pa-0" light>
          <v-list-item avatar>
            <v-list-item-avatar>
              <v-avatar class="primary" size="32px">
                <v-icon dark>person</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                getUserData && getUserData.registration_number
                  ? getUserData && getUserData.registration_number.toUpperCase()
                  : getUserData && getUserData.name
              }}</v-list-item-title>
              <v-list-item-title
                >({{
                  getUserProfile &&
                    getUserProfile.name.toLowerCase().substring(3)
                }})</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--<v-list-item key="profile" @click="doSomething()">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mon Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
          <v-divider></v-divider>

          <v-list-item key="lock_open" @click="onLogout()">
            <v-list-item-icon>
              <v-icon>lock_open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("dashboard.logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu v-if="isMultiLang" v-model="value" close-on-click>
        <template v-slot:activator="{ on }">
          <v-btn class="ml-3" small color="primary" v-on="on">
            <gb-flag :code="selectedLang.icon" size="micro" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="language" color="primary">
            <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="changeLang"
            >
              <v-list-item-icon>
                <gb-flag :code="item.icon" size="micro" />
              </v-list-item-icon>
              <v-list-item-title>{{
                $t(`langs.${item.key}`)
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-btn icon @click="show = true">
        <v-icon>help</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-slide-y-transition mode="out-in" v-if="loaded">
        <router-view :key="$root.$i18n.locale" />
      </v-slide-y-transition>
    </v-content>
  </v-app>
</template>

<script>
import { LayoutOptions } from "../mixins/layouts";
import { mapActions, mapGetters } from "vuex";
import { TreeViewData } from "../mixins/tree-view";
import USER_API from "../api/user";
import TICKET_API from "../api/ticket";
import { loadStates, shouldDisplay, uniqueElementBy } from "../utils";
import { blobToImage } from "../utils/upload";
import { TicketsData } from "../mixins/tickets-data";
import { AssetsData } from "../mixins/asset";
import { Icon } from "leaflet";
import { langs } from "../utils/lang";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  props: {
    source: String
  },
  data() {
    return {
      drawer: true,
      show: false,
      parentRoot: true,
      languages: langs,
      language: undefined,
      value: false
    };
  },
  created() {
    if (this.isAuth && !this.sessionIsInit) {
      if (this.getUserApiToken) {
        this.$store.dispatch("initSession", this.getUserApiToken).then(() => {
          this.loadEquipData();
          this.loadData();
        });
      } else {
        this.killSession();
      }
    }
    /*if (
      this.isAuth &&
      !this.$route.meta.directLink &&
      this.$route.name !== "assets.index"
    ) {
      this.$router.push("/assets/index");
    }*/
    if (!this.getSessionToken) {
      this.killSession();
    }
    if (this.sessionIsInit) {
      this.loadEquipData();
      this.loadData();
      if (this.isAuth && this.loaded) {
        //this.listData();
      }
    }
    if (this.pageTitle) {
      document.title = this.pageTitle;
    }
  },
  mounted() {
    this.language = this.selectedLangIndex;
  },
  computed: {
    ...mapGetters([
      "isAuth",
      "sessionIsInit",
      "getSessionToken",
      "getUserApiToken",
      "getOccupants",
      "getOccupantsLoadStatus",
      "getCharges",
      "getChargesLoadStatus",
      "getTravaux",
      "getTravauxLoadStatus",
      "getAllTickets",
      "getAllTicketsLoadStatus",
      "getDocuments",
      "getDocumentsLoadStatus",
      "getDocumentsImages",
      "getEquipmentLoadStatus",
      "getEquipments",
      "getReservations",
      "getReservationsLoadStatus",
      "getTicketsTasksLoadStatus",
      "getAllITILSolutions",
      "getAllITILSolutionsLoadStatus",
      "getAllITILFollowups",
      "getAllITILFollowupsLoadStatus",
      "getProjectsLoadStatus",
      "getProjectsTaskLoadStatus",
      "getTypeName",
      "getUserId",
      "getUsersLoadingStatus",
      "getActiveEntity"
    ]),
    loaded() {
      return (
        (this.getOccupantsLoadStatus === loadStates.SUCCESS &&
          this.getChargesLoadStatus === loadStates.SUCCESS &&
          this.getTravauxLoadStatus === loadStates.SUCCESS &&
          this.getAllTicketsLoadStatus === loadStates.SUCCESS &&
          this.getDocumentsLoadStatus === loadStates.SUCCESS) ||
        this.$route.meta.directLink
      );
    },
    treeElements() {
      return this.getTreeData;
    },
    pageTitle() {
      if (this.$route.name === "assets.index") {
        return this.$t("dashboard.default_page_title");
      } else if (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.xs
      ) {
        if (this.selectedEntity && this.selectedEntity.level === 5) {
          let name = this.selectedEntity ? this.selectedEntity.name : "";
          return name ? name : this.selectedEntity.name;
        }
        return this.selectedEntity ? this.selectedEntity.name : "";
      } else {
        if (this.selectedEntity && this.selectedEntity.level === 5) {
          let name = this.selectedEntity
            ? this.selectedEntity.completename.split(">")[3] +
              "> " +
              this.selectedEntity.name
            : "";
          return name ? name : this.selectedEntity.name;
        }
        return this.selectedEntity ? this.selectedEntity.name : "";
      }
    },
    isMultiLang() {
      return this.getUserId === 34 || this.getUserId === 14;
    },
    isMobilePaysage() {
      return (
        this.$vuetify.breakpoint.width <
          this.$vuetify.breakpoint.thresholds.md &&
        this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.width
      );
    },
    isDirectLink() {
      return this.$route.meta.directLink;
    },
    IsSessionLoading() {
      return (
        this.getSessionLoadState === loadStates.PENDING ||
        this.getProfileLoadingStatus === loadStates.PENDING
      );
    },
    selectedLangIndex() {
      return this.languages.indexOf(this.selectedLang);
    },
    selectedLang() {
      return this.languages.find(
        lang => lang.local === this.$root.$i18n.locale
      );
    }
  },
  methods: {
    ...mapActions([
      "login",
      "reloadSession",
      "loadOccupants",
      "loadCharges",
      "loadTravaux",
      "loadAllTickets",
      "loadDocuments",
      "killSession",
      "addDocumentsImages",
      "loadEquipment",
      "loadReservations",
      "loadTicketsTasks",
      "loadAllITILSolutions",
      "loadAllITILFollowups",
      "loadProject",
      "loadProjectTasks",
      "loadItemType",
      "loadUsers"
    ]),
    async loadData() {
      if (this.isAuth && this.entitiesLoaded) {
        if (!this.$route.meta.directLink) {
          if (this.getOccupantsLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getOccupants().then(response => {
              this.loadOccupants(response.data);
            });
          }
          if (this.getChargesLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getCharge().then(response => {
              this.loadCharges(response.data);
            });
          }
          if (this.getTravauxLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getTravaux().then(response => {
              this.loadTravaux(response.data);
            });
          }
          if (this.getDocumentsLoadStatus === loadStates.INITIAL_STATE) {
            USER_API.getPhotos().then(response => {
              const docs = response.data.filter(
                entity => entity.documentcategories_id === 2
              );
              this.loadDocuments(docs);
            });
          }

          if (this.getReservationsLoadStatus === loadStates.INITIAL_STATE) {
            this.loadCalendar();
          }
        }

        if (this.getUsersLoadingStatus === loadStates.INITIAL_STATE) {
          this.loadUsers();
        }

        if (this.getAllTicketsLoadStatus === loadStates.INITIAL_STATE) {
          USER_API.getAllTickets().then(async response => {
            const tickets = await this.loadEquipmentsData(response.data);
            TICKET_API.getTasks().then(({ data }) => {
              let tasks = [];
              tickets.forEach(ticket => {
                const ticketTasks = data
                  .filter(task => task.tickets_id === ticket.id)
                  .map(task => {
                    task.entities_id = ticket.entities_id;
                    return task;
                  });
                if (ticketTasks && ticketTasks.length > 0) {
                  tasks = tasks.concat(ticketTasks);
                }
              });
              this.$store.commit("setTicketsTasks", tasks);
              this.$store.commit(
                "setTicketsTasksLoadStatus",
                loadStates.SUCCESS
              );
            });
          });
        }

        if (this.getAllITILSolutionsLoadStatus === loadStates.INITIAL_STATE) {
          USER_API.getAllITILSolutions().then(response => {
            this.loadAllITILSolutions(response.data);
          });
        }
        if (this.getAllITILFollowupsLoadStatus === loadStates.INITIAL_STATE) {
          USER_API.getAllITILFollowups().then(response => {
            this.loadAllITILFollowups(response.data);
          });
        }
        /*if (this.getTicketsTasksLoadStatus === loadStates.INITIAL_STATE) {
          TICKET_API.getTasks().then(({ data }) => {
            this.loadTicketsTasks(data);
          });
        }*/

        if (this.getProjectsTaskLoadStatus === loadStates.INITIAL_STATE) {
          USER_API.getAllProjectTask().then(({ data }) => {
            this.loadProjectTasks(data);
          });
        }
      }
    },
    loadEquipData() {
      if (
        this.isAuth &&
        !this.isSessionLoading &&
        this.getEquipmentLoadStatus === loadStates.INITIAL_STATE
      ) {
        USER_API.getLocations().then(({ data }) => {
          this.loadEquipment(data);
        });
      }
    },
    loadDocumentsImages() {
      this.getDocuments.forEach(doc => {
        USER_API.getDocument(doc.id, true).then(async response => {
          if (!this.getDocumentsImages.find(el => el.id === doc.id)) {
            const img = await blobToImage(response.data);
            this.addDocumentsImages({
              id: doc.id,
              name: doc.name,
              image: img.src,
              entities_id: doc.entities_id
            });
          }
        });
      });
    },
    async loadEquipmentsData(ticketsData) {
      const tickets = ticketsData.map(ticket => {
        /*if (false && ticket.name.startsWith("{") && ticket.name.endsWith("}")) {
          try {
            const name = JSON.parse(ticket.name);
            await this.loadItemType(name.itemtype);
            ticket.name = name.titre;
            ticket.itemtype = name.itemtype;
            ticket.itemtypeID = name.itemtypeID;
            ticket.equipment = this.getTypeName(name.itemtype, name.itemtypeID);
          } catch (error) {
            console.log(error);
          }
        } else {*/
        ticket.equipment =
          ticket.locations_id === 0
            ? this.$t("no_equipment")
            : ticket.locations_id;
        ticket.no_equipment = ticket.locations_id === 0;
        //}
        return ticket;
      });
      this.loadAllTickets(tickets);
      return tickets;
    },
    loadCalendar() {
      TICKET_API.getReservationItems().then(({ data }) => {
        let reservationItems = data;
        TICKET_API.getReservations().then(({ data }) => {
          reservationItems.forEach(async item => {
            let reservations = data
              .filter(
                el => el.reservationitems_id === item.id && shouldDisplay(el)
              )
              .map(el => {
                el.entities_id = item.entities_id;
                return el;
              });
            reservations = uniqueElementBy(
              reservations,
              (a, b) => a.id === b.id
            );
            this.loadReservations(reservations);
          });
        });
      });
    },
    changeLang() {
      this.$nextTick(() => {
        const local = this.languages[this.language].local;
        this.$store.dispatch("changeLang", local);
        this.$root.$i18n.locale = local;
      });
    }
  },
  mixins: [LayoutOptions, TreeViewData, TicketsData, AssetsData],
  watch: {
    /*loaded() {
      if (this.loaded && !this.$route.meta.directLink) {
        this.$router.push("/assets/index");
      }
    },*/
    getDocumentsLoadStatus() {
      if (this.getDocumentsLoadStatus === loadStates.SUCCESS) {
        this.loadDocumentsImages();
      }
    },
    getEquipmentLoadStatus() {
      if (this.getEquipmentLoadStatus === loadStates.SUCCESS) {
        this.loadTicketsData();
      }
    },
    pageTitle() {
      document.title = this.pageTitle;
    },
    entitiesLoaded() {
      this.loadData();
    },
    isSessionLoading() {
      if (!this.isSessionLoading) {
        this.loadEquipData();
      }
    },
    getActiveEntity() {
      this.loadUsers();
    }
  }
};
</script>
<style>
.leaflet-tooltip,
.leaflet-tooltip-pane {
  z-index: 400 !important;
}
.leaflet-pane {
  z-index: inherit !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 1 !important;
}
</style>
