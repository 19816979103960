import { mapActions, mapGetters } from "vuex";
import { orderBy } from "../utils";

export const TreeViewData = {
  data() {
    return {
      treeData: []
    };
  },
  computed: {
    ...mapGetters([
      "getEntity",
      "getEntityLoadStatus",
      "getUserProfile",
      "getUserProfileStatus",
      "isAdminUser"
    ]),
    allEntities() {
      return this.getEntity.map(el => this.loadAdditionnalData(el));
    },
    getLevel2Entities() {
      return this.allEntities.filter(entity => entity.level === 2);
    },
    entities() {
      return this.getEntitiesByLevel(3).map(entity => {
        return entity;
      });
    },
    getTreeData() {
      let entities = [];
      if (this.isAdminUser) {
        entities = this.getLevel2Entities;
        if (entities.length === 0) entities = this.getEntitiesByLevel(3);
      } else {
        entities = this.getEntitiesByLevel(4);
      }

      entities = entities.map(entity => {
        return this.getEntityTree(entity);
      });
      return orderBy(entities, ["label"]);
    },
    selectedEntity() {
      if (
        this.$route.name === "assets.show" ||
        this.$route.name === "lots.show" ||
        this.$route.name === "assets.l2.show"
      ) {
        const assetId =
          this.$route.name === "assets.l2.show"
            ? +this.$route.params.assetID
            : +this.$route.params.id;
        const currentAsset = this.allEntities.find(asset => {
          return asset.id === assetId;
        });
        return currentAsset;
      } else if (
        this.$route.name === "view.lot" ||
        this.$route.name === "view.ticket" ||
        this.$route.name === "view.calendar"
      ) {
        return this.getActiveEntity;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(["loadEntity", "loadUserProfile", "loadEntities"]),
    getEntitiesByLevel(level) {
      return this.allEntities.filter(entity => entity.level === level);
    },
    getEntityTree(entity) {
      return {
        ...entity,
        children: this.getChildren(entity).sort((a, b) => {
          if (b.partiescommunefield === 1) {
            return 1;
          } else if (a.partiescommunefield === 1) {
            return -1;
          }
          return 0;
        })
      };
    },
    getChildren(entity) {
      const children = this.getEntitiesByLevel(entity.level + 1)
        .filter(ent => ent.entities_id === entity.id)
        .map(child => this.getEntityTree(child));
      return orderBy(children, ["label"]);
    },
    loadAdditionnalData(entity) {
      const label = entity.name;
      entity.label = label;
      if (this.loaded) {
        const occupants = this.getOccupants.filter(
          el => el.items_id === entity.id
        );

        if (occupants && occupants.length > 0) {
          const occupant = occupants[0];

          entity.surfacemtwofield = occupant.surfacemtwofield;
          entity.nomcommercialfield = occupant.nomcommercialfield;
          entity.etageportefield = occupant.etageportefield;
          entity.datedbutdebailfield = occupant.datedbutdebailfield;
          entity.datefindebailfield = occupant.datefindebailfield;
          entity.datelimitedersiliationfield =
            occupant.datelimitedersiliationfield;
          entity.partiescommunefield = occupant.partiescommunefield;

          if (entity.level === 5) {
            entity.soldefield = occupant.soldefield;
            entity.loyerannuelhtfield = occupant.loyerannuelhtfield;
          }
        }
        return entity;
      }
      return entity;
    }
  },
  watch: {
    getTreeData: {
      deep: true,
      handler: function() {
        this.$store.commit("setTreeData", this.getTreeData);
      }
    }
  }
};
